<template>
  <AppraisalManage :typePage="typePage"></AppraisalManage>
</template>

<script>
import AppraisalManage from "@/views/modules/collection/business/expertDatabase/appraisalManage.vue";

export default {
    name: "reviewExperts",
    components: {AppraisalManage},
    data(){
        return{
            typePage:'reviewExperts',
        }
    },
}
</script>

<style scoped>

</style>